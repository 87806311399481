











































































import {Component, Vue} from 'vue-property-decorator';
import Settings         from '@/packages/settings/Settings';

@Component({
  components: {}
})
export default class TrialUserForm extends Vue {
  public readonly host = (new Settings).settings.host;

  public email = '';
  public firstname = '';
  public loader = false;

  public constructor() {
    super();
  }

  public addTrialUser(): void {
    console.log(this.email, this.firstname);
    if (this.loader) return;
    if (this.email.length > 0 && this.firstname.length > 0) {
      this.loader = true;
      const vm = this;
      this.axios.post(
          this.host + 'user/demo?type=2',
          {
            firstname: this.firstname,
            email: this.email
          }
      ).then(function (data) {
        vm.loader = false;
        vm.$notify({
          group: 'notify',
          title: 'Success',
          text: 'User has been added',
          type: 'success'
        });
        vm.$bvModal.hide('bv-modal-user-form');
      }, function (error) {
        vm.loader = false;
        vm.$notify({
          group: 'notify',
          title: 'Error',
          text: 'Error while request',
          type: 'error'
        });
      })
    } else {
      this.$notify({
        group: 'notify',
        title: 'Warning',
        text: 'Fill the form correctly!',
        type: 'warn'
      });
    }
  }
}

