<template>
<footer>
  <div class="container p-5">
    <div class="row">
      <div class="col-sm-4">
        <img width="150" src="./../assets/logo-black.svg"/>
        <p class="mt-4">
        </p>
      </div>
      <div class="col-md-4 text-center mt-3 mt-sm-0">
        <p class="font-weight-normal text-black-50 text-uppercase">Contact us</p>
        <a class="text-main" href="mailto:support@influtool.com">
          <b-icon-envelope-fill></b-icon-envelope-fill>
          support@influtool.com
        </a>
      </div>
      <div class="col-md-4 text-right text-sm-center  mt-3 mt-sm-0">
        <p class="font-weight-normal text-black-50 text-uppercase">Links</p>
        <div>
          <a class="nav-item" href="https://influtool.com/privacy.html">Privacy Policy</a>
        </div>
        <div class="mt-2">
          <a class="nav-item" href="https://influtool.com/terms.html">Terms of Use</a>
        </div>
        <div class="mt-2">
          <a class="nav-item" href="https://influtool.com/register/influencer.html">For creators</a>
        </div>
      </div>
    </div>
  </div>

</footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style lang="scss" scoped>
footer {
  a {
    text-decoration: none;
  }
}
</style>
