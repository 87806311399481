<template>
  <div id="app">
    <notifications group="notify" position="top right" />
    <Navbar/>
    <router-view/>
    <Footer/>
  </div>
</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700;800&display=swap');
$main: #007bff;
#app {
  font-family: Poppins, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.text-main {
  color: $main;
}
.btn-primary {
  color: #fff;
  background-color: $main!important;
  border-color: $main!important;
  transition: all 0.3s ease;
  &:hover {
    -webkit-box-shadow: 0 1px 10px rgb(57 139 247 / 40%) !important;
    box-shadow: 0 1px 10px rgb(57 139 247 / 40%) !important;
    background-color: darken($main, 10%)!important;
    border-color: #0056b3!important;
  }
}

.btn-rounded {
  border-radius: 10rem!important;
}

.btn .b-icon.bi {
  margin-right: 10px;
}

.btn-sm .b-icon.bi {
  margin-right: 5px;
}

</style>
<script>
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
export default {
  components: {Footer, Navbar}
}
</script>
