





























































import {Component, Prop, Vue} from "vue-property-decorator";

  @Component({
    components: {}
  })
  export default class RankingMore extends Vue {
    @Prop({ type: Array, required: true }) influencers: Array<any>;
    @Prop({required: true}) filters;

    constructor() {
      super();
      this.influencers = [];
    }
  }
