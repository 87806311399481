

















import { Component, Vue } from 'vue-property-decorator';
import Ranking from "@/components/Ranking.vue";
import Navbar from "@/components/Navbar.vue";
import Slider from "@/components/Slider.vue"; // @ is an alias to /src

@Component({
  components: {
    Slider,
    Ranking
  }
})
export default class Home extends Vue {}
