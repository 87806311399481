<template>
  <div id="nav">
    <b-navbar>
      <b-navbar-brand href="#">
        <img class="logo" src="./../assets/logo-white.svg"/>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <a class="btn btn-light btn-rounded btn-sm px-3" href="https://influtool.com/bookademo.html">
            <b-icon-calendar3></b-icon-calendar3>
            Schedule demo
          </a>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "Navbar"
}
</script>

<style lang="scss" scoped>
.navbar {
  padding: .5rem 1rem;
  background-color: transparent!important;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  .logo {
    width: 150px;
  }
}
</style>
