































import {Component, Prop, Vue} from "vue-property-decorator";
import moment from "moment";

@Component({
  components: {}
})
export default class RankingSort extends Vue {

  sortField = 'object_popularity';
  sortType = 'desc';
  filters: any = {
    date: {
      value: {},
      name: 'Date'
    },
    category: {
      name: 'All categories',
      value: undefined
    },
    channel: {
      name: 'All channels',
      value: undefined
    }
  };

  public sort = [
    {
      name: 'Popularity',
      sort_field: 'object_popularity',
      sort_type: 'desc'
    },
    {
      name: 'Impression',
      sort_field: 'object_reach',
      sort_type: 'desc'
    },
    {
      name: 'Engage',
      sort_field: 'object_engage',
      sort_type: 'desc'
    },
    {
      name: 'No of followers',
      sort_field: 'object_fan',
      sort_type: 'desc'
    },
    {
      name: 'Engage per post',
      sort_field: 'object_engage_per_post',
      sort_type: 'desc'
    }
  ];
  public filter: any = {
    date: [],
    channel: [
      {
        name: 'Facebook',
        value: 1
      },
      {
        name: 'Twitter',
        value: 2
      },
      {
        name: 'YouTube',
        value: 4
      },
      {
        name: 'Instagram',
        value: 5
      },
      {
        name: 'Tiktok',
        value: 6
      }
    ],
    category: [
      {
        name: 'Lifestyle',
        value: 1
      },
      {
        name: 'Music',
        value: 2
      },
      {
        name: 'Fashion & Beauty',
        value: 3
      },
      {
        name: 'Travel',
        value: 4
      },
      {
        name: 'Marketing',
        value: 5
      },
      {
        name: 'Culture & Art',
        value: 6
      },
      {
        name: 'Foto / Video',
        value: 7
      },
      {
        name: 'Food',
        value: 8
      },
      {
        name: 'Technology',
        value: 9
      },
      {
        name: 'Journalist',
        value: 10
      },
      {
        name: 'Automotive',
        value: 11
      },
      {
        name: 'Money',
        value: 12
      },
      {
        name: 'Parenting',
        value: 13
      },
      {
        name: 'Gaming',
        value: 14
      },
      {
        name: 'Sport & Fitness',
        value: 15
      },
      {
        name: 'Football',
        value: 22
      },
      {
        name: 'Entertainment',
        value: 26
      },
      {
        name: 'Design & DIY',
        value: 27
      },
      {
        name: 'Celebrities',
        value: 28
      },
      {
        name: 'Actors',
        value: 29
      },
      {
        name: 'Hobby',
        value: 32
      },
      {
        name: 'Science',
        value: 34
      },
      {
        name: 'Health & diet',
        value: 38
      },
      {
        name: 'Model',
        value: 41
      },
      {
        name: 'Podcast',
        value: 51
      },
      {
        name: 'Eco & Less/Zero Waste',
        value: 53
      }
    ]
  };

  public constructor() {
    super();
    this.filter.date = [0, 1, 2, 3].map(value => {
      const actualDate = moment().subtract(value, 'month');
      return {
        idx: value,
        name: actualDate.format('MMMM YYYY'),
        value: {
          min_time: actualDate.startOf('month').unix(),
          max_time: actualDate.endOf('month').unix()
        }
      }

    });
    this.filters['date'] = {
      idx: this.filter.date[0].idx,
      name: this.filter.date[0].name,
      value: this.filter.date[0].value
    };
  }

  public setFilter(name: string, value: any, displayName: string): void {
    console.log(this.filters);
    this.filters[name].value = value;
    this.filters[name].name = displayName;
    this.$emit('setFilter', {
      name: name,
      value: value
    });
  }

  public getSortBySortField(): any {
    return this.sort.find(v => v.sort_field === this.sortField);
  }

  public setSort(sort: any): void {
    this.sortField = sort.sort_field;
    this.sortType = sort.sort_type;
    this.$emit('setSort', sort);
  }

}
