
















import RankingTable from "@/components/RankingTable.vue";
import RankingSort from "@/components/RankingSort.vue";
import RankingMore from "@/components/RankingMore.vue";
import {Component, Vue} from "vue-property-decorator";
import Settings from "@/packages/settings/Settings";
import TrialUserForm from "@/components/TrialUserForm";
import Slider from "@/components/Slider.vue";
import moment from "moment";
import Loader from "@/components/Loader.vue";

@Component({
  components: {Loader, RankingMore, RankingSort, RankingTable, TrialUserForm}
})
export default class Ranking extends Vue {

  public readonly host = (new Settings).settings.host;
  public influencers = [];
  public loading = false;
  public code: any = undefined;
  public filters: any = {
    date: {
      min_time: moment().subtract(1, 'months').unix(),
      max_time: moment().unix()
    },
    channel: undefined,
    category: undefined
  };

  public sort = {
    sort_field: 'object_popularity',
    sort_type: 'desc'
  };

  public constructor() {
    super();
    console.log(moment().subtract(1, 'months').unix())
  }

  public setFilter(data: {name: string, value: any}) {
    console.log(data);
    this.filters[data.name] = data.value;
    this.getRanking();
  }

  public setSort(sort: any): void {
    this.sort.sort_field = sort.sort_field;
    this.sort.sort_type = sort.sort_type;
    this.getRanking();
  }

  public getRanking() {
    this.loading = true;
    const vm = this;
    this.axios.get(this.host + 'influencer/top/rank', {
      params: {
        'filter[min_time]': this.filters.date.min_time,
        'filter[max_time]': this.filters.date.max_time,
        'filter[channel]': this.filters.channel,
        'filter[category]': this.filters.category,
        'sort_field': this.sort.sort_field,
        'sort_type': this.sort.sort_type,
        'code': this.code
      }
    }).then(function(data: any) {
      vm.loading = false;
      vm.influencers = Object.values(data.data.data.list);
    });
  }

  public created() {
    this.code = this.$route.query.code;
    this.getRanking();
  }



}

