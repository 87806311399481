<template>
<div class="slider">
  <div class="container">
    <h1 class="page-title">TOP10 Influencerów w Polsce</h1>
    <h2 class="page-subtitle">by the number of quality and engaged followers</h2>
    <p class="page-description">Find top Instagram influencers to make the most of your marketing campaigns.</p>
  </div>
  <Ranking/>

</div>
</template>

<script>
import Ranking from "@/components/Ranking";
export default {
  name: "Slider",
  components: {Ranking}
}
</script>

<style  lang="scss" scoped>

.slider {
  background-image: url("./../assets/slider-blue.png");
  //background-size: cover;
  //height: 70vh;
  padding: 100px 30px 0 30px;
  background-size: 100vw 80vh;
  background-repeat: no-repeat;
  color: white;
  .page-title {
    font-weight: 600;
    font-size: 2.5rem;
  }
  .page-subtitle {
    font-weight: 600;
    font-size: 1.25rem;
  }
  .page-description {
    margin: 30px 0;
  }
  @media (max-width: 500px) {
    padding-left: 10px;
    padding-right: 10px;
    .page-title {
      font-size: 1.6rem;
    }
    .page-subtitle {
      font-size: 1.1rem;
    }
  }
}
</style>
